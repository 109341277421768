body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
  width: 100%
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* https://pantaley.com/blog/Create-sticky-header-only-with-React-Hooks/   */
.sticky-wrapper {
  position: relative;
  overflow: auto;

  height: 10rem; /* We need to change this value */
}

.sticky .sticky-inner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;

}
.sticky {
  position: sticky;
  top: 0;

  z-index: 100; /* this is optional and should be different for every project */
}

.small {

}

.playing
{
  fill: none;
    stroke: grey;
    stroke-width: 2;
    stroke-dasharray: 10;
}

.path1
{
      stroke-dasharray: 10;
    stroke-dashoffset: 0;
  animation: dash .8s ease-in-out;
  animation-iteration-count: infinite;
}

.path2
{
      stroke-dasharray: 10;
    stroke-dashoffset: 0;
  animation: dash .7s ease-in-out;
  animation-iteration-count: infinite;
}

.path3
{
      stroke-dasharray: 10;
    stroke-dashoffset: 0;
  animation: dash .65s ease-in-out;
  animation-iteration-count: infinite;
}

.path4
{
      stroke-dasharray: 10;
    stroke-dashoffset: 0;
  animation: dash .85s ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes dash {
 
  0%, 100% {
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dashoffset: 10;
  }
  }